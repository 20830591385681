import React, { FunctionComponent, useCallback } from "react"
import { Helmet as Head } from "react-helmet"
import ChannelView from "../components/channel/view"
import Bookmarks from "../components/layout/bookmarks"
import ChannelFeeds from "../components/layout/channel-feeds"
import SortFilter from "../components/layout/sort-filter"
import TagFilters from "../components/layout/tag-filters"
import ResponsiveLayout from "../components/layout/responsive-layout"
import { useChannel } from "../hooks/use-channel"
import { useFilters } from "../hooks/use-filters"
import { useIndexedDB } from "../hooks/use-indexeddb"
import { convertChannels } from "../services/content"
import { GET_channels } from "../services/http"

const WebsitesPage: FunctionComponent = () => {
  const { channel, donationsEnabled } = useChannel("", "amount")
  const { filters } = useFilters()
  const { getLikedAuthors, getLikedWebsites } = useIndexedDB()

  const fetchPage = useCallback(
    async (offset: number) => {
      //console.log("fetchPage")
      const kind = 1
      if (filters.liked == false) {
        const response = await Promise.resolve(
          // TODO: prevent requests if no data
          //fetchItems(offset)
          GET_channels({
            count: 20,
            offset: 20 * offset,
            q: filters.q,
            sort: filters.sort,
            age: filters.age,
            languages: filters.languages,
            countries: filters.countries,
            channel_ids: filters.channel_ids,
            tags: filters.tags,
            filter: !filters.moderated ? 0 : filters.bitmask,
            kind: kind,
            nc: filters.nc,
            mod: filters.mod,
            // TODO: tags (parse q before sending?)
          })
        )
        if (response?.hits?.hits.length) {
          let items = convertChannels(response, filters)
          //console.log(items)
          return items
        } else return []
      } else if (kind == 1) {
        console.log("fetchLikedPage")
        const response = await Promise.resolve(
          getLikedWebsites(20 * offset, 20, filters)
        )
        if (response && response.length) {
          return response
        } else return []
      } else if (kind == 2) {
        console.log("fetchLikedPage")
        const response = await Promise.resolve(
          getLikedAuthors(20 * offset, 20, filters)
        )
        if (response && response.length) {
          return response
        } else return []
      }
    },
    [filters]
  )

  return (
    <>
      <Head>
        <title>Smartlike</title>
        <meta name="description" content="Smartlike p2p donations" />
        <meta property="og:title" content="Smartlike" />
        <meta property="og:description" content="Smartlike p2p donations" />
      </Head>

      <ResponsiveLayout channel={channel}>
        {{
          content: <ChannelFeeds fetchPage={fetchPage} />,
          sidebar: (
            <>
              <SortFilter />
              <ChannelView
                channel={channel}
                donationsEnabled={donationsEnabled}
              />
              <TagFilters />
              <Bookmarks />
            </>
          ),
        }}
      </ResponsiveLayout>
    </>
  )
}
export default WebsitesPage
